import { default as index5zXKZa8HElMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/index.vue?macro=true";
import { default as loginp9vOt33JcxMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/login.vue?macro=true";
import { default as _91id_93lmybxJ6qAHMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/orders/[id].vue?macro=true";
import { default as createExQyghD7ZlMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/orders/create.vue?macro=true";
import { default as indexmSXSY0jARRMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/orders/index.vue?macro=true";
import { default as print_45jobs16O7cewLccMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/print-jobs.vue?macro=true";
import { default as indexKnNhhxDI5gMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/reservations/index.vue?macro=true";
import { default as lanesH5gBe37I44Meta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/reservations/lanes.vue?macro=true";
import { default as settlementlWm3Lnke8qMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/settlement.vue?macro=true";
import { default as indexkOkY770lLcMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/setup/[merchantId]/index.vue?macro=true";
import { default as _91storeId_93VkdXykc4gEMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/setup/[merchantId]/stores/[storeId].vue?macro=true";
import { default as indexPvIgbfYWljMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/setup/index.vue?macro=true";
import { default as stock_45statuseshovyMVvA6SMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/stock-statuses.vue?macro=true";
import { default as indexRWWqu5PNT0Meta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/stock/index.vue?macro=true";
import { default as create0hQySHMcakMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/stock/statuses/create.vue?macro=true";
import { default as createq9nDAl4cJfMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/stock/transfers/create.vue?macro=true";
import { default as index4zt6wRhAziMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/tables/[tableId]/orders/[orderId]/index.vue?macro=true";
import { default as splitdiyHDEohINMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/tables/[tableId]/orders/[orderId]/split.vue?macro=true";
import { default as _91orderId_93uCxxXwkQ1OMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/tables/[tableId]/orders/[orderId].vue?macro=true";
import { default as createshA4dazFz1Meta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/tables/[tableId]/orders/create.vue?macro=true";
import { default as _91tableId_93IiFOO9kcBJMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/tables/[tableId].vue?macro=true";
import { default as indexs99w3LRWkCMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/tables/index.vue?macro=true";
import { default as indexbLv4DCMQOqMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/takeaway-orders/[id]/index.vue?macro=true";
import { default as split7OvCUP0rwTMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/takeaway-orders/[id]/split.vue?macro=true";
import { default as _91id_934kOdDMHqCZMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/takeaway-orders/[id].vue?macro=true";
import { default as createdHbgTig6lpMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/takeaway-orders/create.vue?macro=true";
import { default as indexSUxbTrUZsBMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/takeaway-orders/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginp9vOt33JcxMeta || {},
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders-create",
    path: "/orders/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/orders/create.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "print-jobs",
    path: "/print-jobs",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/print-jobs.vue").then(m => m.default || m)
  },
  {
    name: "reservations",
    path: "/reservations",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/reservations/index.vue").then(m => m.default || m)
  },
  {
    name: "reservations-lanes",
    path: "/reservations/lanes",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/reservations/lanes.vue").then(m => m.default || m)
  },
  {
    name: "settlement",
    path: "/settlement",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/settlement.vue").then(m => m.default || m)
  },
  {
    name: "setup-merchantId",
    path: "/setup/:merchantId()",
    meta: indexkOkY770lLcMeta || {},
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/setup/[merchantId]/index.vue").then(m => m.default || m)
  },
  {
    name: "setup-merchantId-stores-storeId",
    path: "/setup/:merchantId()/stores/:storeId()",
    meta: _91storeId_93VkdXykc4gEMeta || {},
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/setup/[merchantId]/stores/[storeId].vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "/setup",
    meta: indexPvIgbfYWljMeta || {},
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/setup/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses",
    path: "/stock-statuses",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/stock-statuses.vue").then(m => m.default || m)
  },
  {
    name: "stock",
    path: "/stock",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/stock/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "/stock/statuses/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/stock/statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-transfers-create",
    path: "/stock/transfers/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/stock/transfers/create.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId",
    path: "/tables/:tableId()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/tables/[tableId].vue").then(m => m.default || m),
    children: [
  {
    name: _91orderId_93uCxxXwkQ1OMeta?.name,
    path: "orders/:orderId()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/tables/[tableId]/orders/[orderId].vue").then(m => m.default || m),
    children: [
  {
    name: "tables-tableId-orders-orderId",
    path: "",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/tables/[tableId]/orders/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId-orders-orderId-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/tables/[tableId]/orders/[orderId]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables-tableId-orders-create",
    path: "orders/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/tables/[tableId]/orders/create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables",
    path: "/tables",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/tables/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_934kOdDMHqCZMeta?.name,
    path: "/takeaway-orders/:id()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/takeaway-orders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "takeaway-orders-id",
    path: "",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/takeaway-orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders-id-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/takeaway-orders/[id]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "takeaway-orders-create",
    path: "/takeaway-orders/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/takeaway-orders/create.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders",
    path: "/takeaway-orders",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250402073018/pages/takeaway-orders/index.vue").then(m => m.default || m)
  }
]